import React, { forwardRef, useEffect, useState, useRef } from 'react';

const VideoContainer = forwardRef((props, ref) => {
  const {
    otp,
    playbackInfo,
    configuration,
    metadata,
    onIframeLoad,
    onPlay,
    onPause,
    onCanPlay,
    onTimeUpdate
  } = props;

  const [currentTime, setCurrentTime] = useState(0);
  const [nextPausePoint, setNextPausePoint] = useState(null);
  const [status, setStatus] = useState('idle');
  const [displayMessage, setDisplayMessage] = useState('');
  const [pausePoints, setPausePoints] = useState([]);
  const playerRef = useRef(null);

  const pausePointsRef = useRef([]);
  const nextPauseIndexRef = useRef(0);

  const params = new URLSearchParams({
    otp,
    playbackInfo,
    ...configuration,
  }).toString();

  const iframeSrc = `https://player.vdocipher.com/v2/?${params}`;

  useEffect(() => {
    if (metadata && metadata.length > 0) {
      const processedMetadata = metadata
        .filter(item => item.t)
        .map((item) => {
          const timeParts = item.t.split(':').map(Number);
          const seconds = timeParts.reduce((acc, val) => acc * 60 + val, 0);
          return { ...item, timeInSeconds: seconds };
        });
      setPausePoints(processedMetadata);
      pausePointsRef.current = processedMetadata;
      if (processedMetadata.length > 0) {
        setNextPausePoint(processedMetadata[0].timeInSeconds);
        nextPauseIndexRef.current = 0;
      }
    }
  }, [metadata]);

  useEffect(() => {
    const iframe = document.querySelector("#vdocipher-iframe");
    const player = window.VdoPlayer.getInstance(iframe);
    playerRef.current = player;

    const resetCurrentTime = () => {
      player.video.currentTime = 0;
    };
    iframe.addEventListener('load', resetCurrentTime);

    const handlePlay = () => {
      setStatus('Playing');
      if (onPlay) onPlay();
    };

    const handlePause = () => {
      setStatus('Paused');
      if (onPause) onPause();
    };

    const handleCanPlay = () => {
      setStatus('Ready');
      if (onCanPlay) onCanPlay();
    };

    const handleSeeking = () => {
      const time = player.video.currentTime;
      setCurrentTime(time);
      setDisplayMessage('');
      let index = 0;
      while (index < pausePointsRef.current.length && pausePointsRef.current[index].timeInSeconds <= time) {
        index++;
      }
      nextPauseIndexRef.current = index;
      if (nextPauseIndexRef.current < pausePointsRef.current.length) {
        setNextPausePoint(pausePointsRef.current[nextPauseIndexRef.current].timeInSeconds);
      } else {
        setNextPausePoint(null);
      }
    };

    const handleTimeUpdate = () => {
      const time = player.video.currentTime;
      setCurrentTime(time);
      if (nextPauseIndexRef.current < pausePointsRef.current.length) {
        const nextPoint = pausePointsRef.current[nextPauseIndexRef.current];
        if (time >= nextPoint.timeInSeconds) {
          // player.video.pause();
          // setDisplayMessage(nextPoint.label);
          // 更新 nextPauseIndex
          nextPauseIndexRef.current += 1;
          if (nextPauseIndexRef.current < pausePointsRef.current.length) {
            setNextPausePoint(pausePointsRef.current[nextPauseIndexRef.current].timeInSeconds);
          } else {
            setNextPausePoint(null);
          }
        }
      }
      if (onTimeUpdate) onTimeUpdate(time);
    };

    // 添加事件监听器
    player.video.addEventListener('play', handlePlay);
    player.video.addEventListener('pause', handlePause);
    player.video.addEventListener('canplay', handleCanPlay);
    player.video.addEventListener('timeupdate', handleTimeUpdate);
    player.video.addEventListener('seeking', handleSeeking); // 添加 seeking 事件监听器

    // 清理事件监听器
    return () => {
      iframe.removeEventListener('load', resetCurrentTime);
      player.video.removeEventListener('play', handlePlay);
      player.video.removeEventListener('pause', handlePause);
      player.video.removeEventListener('canplay', handleCanPlay);
      player.video.removeEventListener('timeupdate', handleTimeUpdate);
      player.video.removeEventListener('seeking', handleSeeking); // 移除 seeking 事件监听器
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === ' ' || event.key === 'PageDown') {
        event.preventDefault();
        if (playerRef.current) {
          playerRef.current.video.play();
        }
        setDisplayMessage('');
      }
    };
    const handleClick = () => {
      if (playerRef.current) {
        playerRef.current.video.play();
      }
      setDisplayMessage('');
    };

    if (displayMessage !== '') {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClick);
      window.addEventListener('touchstart', handleClick);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClick);
      window.removeEventListener('touchstart', handleClick);
    };
  }, [displayMessage]);

  return (
    <div style={{ position: 'relative' }}>
      <iframe
        id="vdocipher-iframe"
        ref={ref}
        src={iframeSrc}
        allowFullScreen
        width="1920px"
        height="1080px"
        allow="autoplay; encrypted-media"
        style={{ overflow: 'auto' }}
      />
      {displayMessage && (
        <div
          className="pause-message"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end', 
            alignItems: 'center',
            color: '#fff',
            zIndex: 1,
            paddingBottom: '20px', 
          }}
        >
          <p style={{ fontSize: '24px', margin: '0 0 10px 0' }}>{displayMessage}</p>
          <p style={{ fontSize: '12px' }}>按空白鍵繼續播放</p>
        </div>
      )}
      <h5>
        {status} - {Math.floor(currentTime)}/{nextPausePoint !== null ? nextPausePoint : 'End'} #{nextPauseIndexRef.current}
      </h5>
    </div>
  );
});

export default VideoContainer;
