import React, { useEffect, useState, useRef } from 'react';
import FsLightbox from 'fslightbox-react';
import RepoVideo from '../repository/RepoVideo';
import VideoContainer from './VideoContainer'; // Adjust the import path accordingly

const ChapterVideo = (props) => {
  const videoRef = useRef(null);
  const [posterUrl, setPosterUrl] = useState(
    'https://www.happinessvillage.org/asset/c_18_40_hv/img/logo/header_lg_logo.png'
  );
  const { video } = props;
  const [isLightBoxOpened, setLightboxController] = useState(false);
  const [otp, setOtp] = useState();
  const [playbackInfo, setPlaybackInfo] = useState();

  useEffect(async () => {
    if (!video) return;
    try {
      const response = await fetch(
        `https://dev.vdocipher.com/api/meta/${video.id}`
      );
      const data = await response.json();
      const poster = data.posters.find((poster) => poster.height === 480);
      if (poster) {
        setPosterUrl(poster.url);
      }
    } catch (error) {
      console.error('Error fetching poster:', error);
    }
  }, [video.id]);

  
  const fetchOtpPlaybackInfo = async (videoId) => {
    try {
      return await RepoVideo.getOtp(video.id);
    } catch (error) {
      console.error('Error fetching otp and playbackInfo:', error);
      return null;
    }
  };

  const openLightbox = async () => {
    const vdoInfo = await fetchOtpPlaybackInfo(video.id);
    if (!vdoInfo) return;
    setOtp(vdoInfo.otp);
    setPlaybackInfo(vdoInfo.playbackInfo);
    setLightboxController(!isLightBoxOpened);
  };

  return (
    <div>
      <img
        src={posterUrl}
        alt="Chapter Thumbnail"
        style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
        onClick={openLightbox}
      />
      <FsLightbox
        toggler={isLightBoxOpened}
        disableBackgroundClose={true}
        onOpen={(instance) => {
          const fullscreenToggleBtn = document.querySelector('.fslightbox-toolbar div:first-child');
          if (fullscreenToggleBtn) {
            fullscreenToggleBtn.style.display = 'none';
          };
          const spinner = document.querySelector('.fslightboxl');
          if (spinner) {
            spinner.style.display = 'none';
          }
        }}
        sources={[
          <VideoContainer
            ref={videoRef}
            key="video-container"
            otp={otp}
            playbackInfo={playbackInfo}
            metadata={video.metadata}
            configuration={{ autoplay: true }}
            onPlay={() => console.log('Video started playing')}
            onPause={() => console.log('Video paused')}
            onCanPlay={() => { 
              console.log('Video is ready to play');
            }}
            onTimeUpdate={(currentTime) => {
              // console.log('Current video time:', currentTime);
            }}
          />]} />
    </div>
  );
};

export default ChapterVideo;
