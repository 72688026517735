import React, { useRef, useEffect, useContext, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';
import RepoVideo from '../repository/RepoVideo';
import authService from './api-authorization/AuthorizeService';
import NextVideo from './NextVideo';
import { UserContext } from "../App";
import useVdoCipher from "../hooks/use-vdocipher";

async function sendVideoAction(video_id, action_id, class_id) {
  const token = await authService.getAccessToken();
  await fetch(`videos/${video_id}/actions/${action_id}?class=${class_id}`, {
    method: 'POST',
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  });
}

const Video = (props) => {

  const videoDiv = useRef(null);
  const iframeRef = useRef(null);
  const { myClasses } = useContext(UserContext);

  // 从路由参数获取视频信息
  let { classId, chapterIdx, sectionIdx, videoIdx } = props.match.params;

  const targetClass = (myClasses.length && myClasses.length > 0) ? myClasses.find(x => x.id == classId) : null;

  const course = (targetClass != null) ? JSON.parse(targetClass.courseData) : { chapters: [{ sections: [{ videos: [{ title: "" }] }] }] };

  chapterIdx = parseInt(chapterIdx);
  sectionIdx = parseInt(sectionIdx);
  videoIdx = parseInt(videoIdx);
  const chapter = course.chapters[chapterIdx];
  const section = chapter.sections[sectionIdx];
  const video = section.videos[videoIdx];

  // 当切换到新的视频页面时，发送切换页面的动作
  useEffect(() => {
    if (video != null) sendVideoAction(video.id, 1, classId); // switch to page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  function isLastSection() {
    return (sectionIdx === (chapter.sections.length - 1));
  }

  function isLastVideo() {
    return (videoIdx === (section.videos.length - 1));
  }

  function isLastChapter() {
    return (chapterIdx === (course.chapters.length - 1));
  }

  function nextVideo() {
    if (isLastVideo()) {
      if (isLastSection()) {
        if (isLastChapter()) {
          return null;
        }
        return {
          classId: classId,
          chapterIdx: chapterIdx + 1,
          sectionIdx: 0,
          videoIdx: 0,
          title: course.chapters[chapterIdx + 1].sections[0].videos[0].title
        };
      }
      return {
        classId: classId,
        chapterIdx: chapterIdx,
        sectionIdx: sectionIdx + 1,
        videoIdx: 0,
        title: course.chapters[chapterIdx].sections[sectionIdx + 1].videos[0].title
      };
    }
    return {
      classId: classId,
      chapterIdx: chapterIdx,
      sectionIdx: sectionIdx,
      videoIdx: videoIdx + 1,
      title: course.chapters[chapterIdx].sections[sectionIdx].videos[videoIdx + 1].title
    };
  }

  const { loadVideo, isAPIReady } = useVdoCipher();

  // 使用 useRef 来管理播放进度的状态
  const registered30 = useRef(false);
  const registered50 = useRef(false);
  const registered90 = useRef(false);
  const ended = useRef(false);

  const [iframeLoaded, setIframeLoaded] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    // 当路由参数或 API 准备好时，加载和设置播放器
    if (myClasses.length > 0 && isAPIReady && video) {
      loadVideoAndSetupPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myClasses.length, isAPIReady, classId, chapterIdx, sectionIdx, videoIdx]);

  async function loadVideoAndSetupPlayer() {
    // 清理之前的播放器实例和事件监听器
    if (playerRef.current && playerRef.current.video) {
      playerRef.current.video.pause();
    }
    if (iframeRef.current) {
      iframeRef.current.remove();
      iframeRef.current = null;
    }

    // 重置播放进度状态
    registered30.current = false;
    registered50.current = false;
    registered90.current = false;
    ended.current = false;
    setIframeLoaded(false);

    try {
      var { otp, vid } = await RepoVideo.getOtp(video.id);
    } catch (e) {
      console.log(e);
      alert('登入逾時、請重新登入系統');
      window.location = '/authentication/login';
    }

    console.log("load video-----");

    var playbackInfo = btoa(JSON.stringify({ videoId: vid }));
    var iframe = loadVideo({
      otp: otp,
      playbackInfo: playbackInfo,
      container: videoDiv.current,
    });

    iframeRef.current = iframe;

    iframe.onload = function () {
      setIframeLoaded(true);
    };
  }

  useEffect(() => {
    if (!iframeLoaded || !iframeRef.current) return;

    const iframe = iframeRef.current;
    const player = window.VdoPlayer.getInstance(iframe);
    playerRef.current = player;

    const handlePlay = () => {
      sendVideoAction(video.id, 2, classId); // play
      console.log("play event called");
    };

    const handlePause = () => {
      console.log("pause event called");
    };

    const handleEnded = () => {
      console.log("ended event called");
      if (registered30.current && registered50.current && registered90.current) {
        sendVideoAction(video.id, 3, classId); // 30% 
        sendVideoAction(video.id, 4, classId); // 50% 
        sendVideoAction(video.id, 5, classId); // 90%
        sendVideoAction(video.id, 6, classId); // Finish
      } else {
        sendVideoAction(video.id, 7, classId); // Ending while not finished all checkpoints
      }
    };

    const handleSeeking = () => {
      console.log("seeking event called");
    };

    const handleProgress = () => {
      const { currentTime, duration } = player.video;
      const view_ratio = currentTime / duration;

      if (player.video.ended) {
        if (!ended.current) {
          ended.current = true;
          console.log("progress ended-!");
        }
        return;
      }
      if (!registered30.current && (view_ratio > 0.3 && view_ratio < 0.35)) {
        registered30.current = true;
        sendVideoAction(video.id, 3, classId); // 30% 
      }
      if (!registered50.current && (view_ratio > 0.5 && view_ratio < 0.55)) {
        registered50.current = true;
        sendVideoAction(video.id, 4, classId); // 50%
      }
      if (!registered90.current && (view_ratio > 0.88 && view_ratio < 0.92)) {
        registered90.current = true;
        sendVideoAction(video.id, 5, classId); // 90%
      }
    };

    // 添加事件监听器
    player.video.addEventListener('play', handlePlay);
    player.video.addEventListener('pause', handlePause);
    player.video.addEventListener('ended', handleEnded);
    player.video.addEventListener('seeking', handleSeeking);
    player.video.addEventListener('timeupdate', handleProgress);

    // 清理事件监听器
    return () => {
      if (player && player.video) {
        player.video.removeEventListener('play', handlePlay);
        player.video.removeEventListener('pause', handlePause);
        player.video.removeEventListener('ended', handleEnded);
        player.video.removeEventListener('seeking', handleSeeking);
        player.video.removeEventListener('timeupdate', handleProgress);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeLoaded, video]);

  return (
    (targetClass == null) ? <></> :
      <Container>
        <Row>
          <Col>
            <h2>{section.name}</h2>
            <h4>{video.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <div ref={videoDiv}></div>
          </Col>
        </Row>
        <Row>
          <Col lg={true}>
            <HashLink to={`/Class/${classId}#SEC-${chapterIdx}-${sectionIdx}`}>
              <Button className="float-left">^ 回課程大綱</Button>
            </HashLink>
            <NextVideo nextVideo={nextVideo()} />
          </Col>
        </Row>
      </Container>
  );
}

export default Video;
