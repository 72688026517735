import React, { useEffect, useContext, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Course } from './Course';
import { browserName, getUA, isMacOs, isDesktop, isChrome, isMobile } from "react-device-detect";
import { UserContext } from "../App";
import RepoMe from '../repository/RepoMe';


const AgreementCard = (props) => {
  const { classData } = props;
  if( classData == null ) return <></>;
  const handleClick = () => {
    RepoMe.checkAgreement(classData.id).then(
      response => {
        window.location.reload();
      }
    );
  };
  const agreementContent = JSON.parse(classData.agreementData);

  return (classData) ? <Card>
  <Card.Header><h2>《{(agreementContent.heading)? agreementContent.heading : "課程同意書"}》</h2></Card.Header>
  <Card.Body>
    { agreementContent.terms.map(
      (x, i) => {
          let termKey = "term-"+i;
          return <p key={termKey} style={{fontSize:'14pt'}}>{x}</p>
         }
    ) }
    <Button onClick={handleClick}>我已閱讀並同意上述說明</Button>
  </Card.Body>
</Card> : <></>;
}


const EQClass = (props) => {

  const { myClasses, user } = useContext(UserContext);
  const [ classLogs, setClassLogs] = useState(null);
  
  const { classId } = props.match.params;
  console.log(classId);
  console.log(myClasses);
  const classData = (myClasses.length && myClasses.length > 0) ? myClasses.find( x=> x.id == classId ) : null;
  const agreementChecked = (classData) ? classData.agreementChecked : false;

  useEffect(()=> {
    RepoMe.getVideoLogs(classId).then(response => {
      if( response && Array.isArray(response) ){
        var result = response.reduce( function(map, obj){
          if( !(obj.videoId in map) ){ map[obj.videoId] = new Array(); };
          map[obj.videoId].push( obj.videoActionId );
          return map;
        },{})
        setClassLogs(result);
      }
    })
    .catch(e => {
      console.log(e);
    });;

  } , []);

  
  if (isMacOs && isDesktop && (!isMobile) && (!isChrome)) {
    return <Card>
      <Card.Header><h2>相容性提示 ({browserName})</h2></Card.Header>
      <Card.Body>
        <h4>
          請先下載安裝 <a href="https://www.google.com/chrome/">Chrome 瀏覽器</a> 以後用 Chrome 進入。
        </h4>
      </Card.Body>
    </Card>;
  }

  return (
    ( getUA.indexOf("Line") > -1 ) ?
      <Card>
        <Card.Header><h2>相容性提示</h2></Card.Header>
        <Card.Body>
          <h4>
          手機上用 Line 開啟連結有可能無法正確播放影片。<br/><br/> 用 <a href="?openExternalBrowser=1">獨立瀏覽器</a> 觀看課程。
          </h4>
        </Card.Body>
      </Card> 
      : ( user !== null ) ? 
        <div>
          {(agreementChecked) ? <Course data={classData} classId={classId} classLogs={classLogs} /> : <AgreementCard classData={classData} /> }
          <div style={{color:'#fff'}}>[{getUA}]</div>
        </div> : <div></div>
  );
}

export default EQClass;