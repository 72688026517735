import React, { useEffect, useContext, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { NewSelfCourse } from './NewSelfCourse';
import { browserName, getUA, isMacOs, isDesktop, isChrome, isMobile } from "react-device-detect";
import { UserContext } from "../App";
import RepoMe from '../repository/RepoMe';


const NewSelfClass = (props) => {

  const { user } = useContext(UserContext);
  const { grade, semester, mode } = props.match.params;

  if (isMacOs && isDesktop && (!isMobile) && (!isChrome)) {
    return <Card>
      <Card.Header><h2>相容性提示 ({browserName})</h2></Card.Header>
      <Card.Body>
        <h4>
          請先下載安裝 <a href="https://www.google.com/chrome/">Chrome 瀏覽器</a> 以後用 Chrome 進入。
        </h4>
      </Card.Body>
    </Card>;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '720px', width: '100%' }}>
        {(getUA.indexOf("Line") > -1) ?
          <Card>
            <Card.Header><h2>相容性提示</h2></Card.Header>
            <Card.Body>
              <h4>
                手機上用 Line 開啟連結有可能無法正確播放影片。<br /><br /> 
                用 <a href="?openExternalBrowser=1">獨立瀏覽器</a> 觀看課程。
              </h4>
            </Card.Body>
          </Card>
          : (user !== null) ?
            <div>
              <NewSelfCourse grade={grade} semester={semester} mode={mode} />
              <div style={{ color: '#fff' }}>[{getUA}]</div>
            </div> : <div>登入使用者不存在，請聯絡協會排除問題。</div>
        }
      </div>
    </div>
  );
}

export default NewSelfClass;