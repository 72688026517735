import Repo from './Base';
import authService from '../components/api-authorization/AuthorizeService';

const resource = '/me';

export default {

  async getMe() {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}`,
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },

  async getMyClasses() {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}/classes`, 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },

  async getMyCourses() {

    const token = await authService.getAccessToken();
    try {
      let result = await Repo.get(
        `${resource}/rotary/courses`,   
        { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
      if (result.status === 200) {
        console.log(result.data);
        const parsedData = result.data.map(item => JSON.parse(item));
        return parsedData;
      }
    } catch (error) {
      if (error.response && (error.response.status === 404 || error.response.status === 500)) {
        console.error(`Error: ${error.response.status} - ${error.response.statusText}`);
      } else {
        console.error('An unexpected error occurred:', error);
      }
      throw error;
    }
  },

  async checkAgreement(classId) {
    const token = await authService.getAccessToken();
    let result = await Repo.post(
      `/Classes/${classId}/acceptAgreement`, "", 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      let retval = result.data;
      return true;
    } else {
      return false;
    }
  },
  
  async getVideoLogs(classIdId) {
    const token = await authService.getAccessToken();
    let result = await Repo.get(
      `${resource}/classes/${classIdId}/videoLogs`, 
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },
    
}