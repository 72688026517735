import React, { StrictMode, useState, useEffect, createContext, useRef } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Detector } from './components/Detector';
import NewSelfClass from './components/NewSelfClass';
import EQClass from './components/EQClass';
import Video from './components/Video';
import authService from './components/api-authorization/AuthorizeService';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import RepoMe from './repository/RepoMe';

import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css';

export const UserContext = createContext(null);

export default function App() {
  const [myClasses, setMyClasses] = useState([]);
  const [user, setUser] = useState(null);
  const [loadError, setLoadError] = useState(null);

  return (
    <StrictMode>
      <UserContext.Provider value={{ myClasses, user, loadError }}>
        <BrowserRouter>
          <AppContent
            setUser={setUser}
            setMyClasses={setMyClasses}
            setLoadError={setLoadError}
          />
        </BrowserRouter>
      </UserContext.Provider>
    </StrictMode>
  );
}

function AppContent({ setUser, setMyClasses, setLoadError }) {
  const location = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const x = authService.subscribe(() => {
      populateState();
    });
    populateState();
    return () => {
      authService.unsubscribe(x);
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function populateState() {
    const [isAuth] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    let me = {};
    let myClasses = [];
    try {
      me = await RepoMe.getMe();
      console.log(me);
      myClasses = await RepoMe.getMyClasses();
    } catch (err) {
      if (isMounted.current) {
        setLoadError(err);
      }
      if (isAuth) {
        if (err.status === 401) {
          if (!location.pathname.startsWith(ApplicationPaths.ApiAuthorizationPrefix)) {
            window.location = "/authentication/login";
          }
        }
        console.log("is Auth ", ApplicationPaths.ApiAuthorizationPrefix);
        console.log(err);
      } else {
        console.log(err);
      }
    }
    if (isMounted.current) {
      console.log("--- authenticated. call set user & set my courses.");
      setUser(me);
      console.log("myClasses: ", myClasses);
      setMyClasses(myClasses);
    }
    if (!isAuth) {
      console.log("--- not authenticated.");
    }
  }

  return (
    <Layout>
      <Switch>
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Route exact path='/t' component={Detector} />
        <AuthorizeRoute exact path='/' component={Home} />
        <AuthorizeRoute exact path='/Course/Y:grade-S:semester' component={NewSelfClass} />
        <AuthorizeRoute exact path='/Course/Y:grade-S:semester/:mode' component={NewSelfClass} />
        <AuthorizeRoute exact path='/Class/:classId' component={EQClass} />
        <AuthorizeRoute
          path='/Class/:classId/video-:chapterIdx-:sectionIdx-:videoIdx'
          component={Video}
        />
      </Switch>
    </Layout>
  );
}
